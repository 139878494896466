"use client";

import { FormSubmit } from "@/components/bulkimp-form/form-submit";
import { FormTextarea } from "@/components/bulkimp-form/form-textarea";
import { Button } from "@/components/ui/button";
import { useAction } from "@/hooks/use-actions";
import { createCard } from "@/actions/main-board-actions/create-card";
import { toast } from "sonner";
import { useRef, ElementRef } from "react";
import { useCustomModal } from "@/hooks/use-custom-modal";
import { useQuery, useMutation } from "convex/react";
import { api } from "@/convex/_generated/api";
import { Id } from "@/convex/_generated/dataModel";
export const TaskForm = () => {
  const {
    projectId,
    onClose
  } = useCustomModal();
  const formRef = useRef<ElementRef<"form">>(null);
  const createDefaultList = useMutation(api.pmAi.lists.createDefaultList);

  // Get the current project using the projectId from the modal
  const currentProject = useQuery(api.pmAi.pmAiProjects.getProjectByProjectId, projectId ? {
    projectId: projectId as string
  } : "skip");

  // Only query if we have a valid project
  const firstList = useQuery(api.pmAi.lists.getFirstListByProjectId, currentProject?.data?._id ? {
    projectId: currentProject.data._id
  } : "skip");
  const {
    execute,
    fieldErrors
  } = useAction(createCard, {
    onSuccess: data => {
      toast.success(`${data.title} created successfully`);
      formRef.current?.reset();
      onClose();
    },
    onError: error => {
      toast.error(`Failed to create card`);
    }
  });
  const onSubmit = async (formData: FormData) => {
    const title = formData.get("title") as string;
    if (currentProject?.error) {
      toast.error(currentProject.error.message);
      return;
    }
    if (!currentProject || !currentProject.data?._id) {
      toast.error("No project found");
      return;
    }
    let listId: Id<"lists">;

    // If no list exists, create a default one
    if (!firstList?.data) {
      const result = await createDefaultList({
        projectId: currentProject.data._id
      });
      if (result.error) {
        toast.error(result.error.message);
        return;
      }
      if (!result.data) {
        toast.error("Failed to create default list");
        return;
      }
      listId = result.data._id;
    } else {
      listId = firstList.data._id;
    }
    execute({
      title,
      boardId: currentProject.data._id,
      listId
    });
  };
  if (!currentProject) {
    return null;
  }
  return <form ref={formRef} action={onSubmit} className="m-1 space-y-4 px-1 py-0.5" data-sentry-component="TaskForm" data-sentry-source-file="task-form.tsx">
      <FormTextarea id="title" placeholder="Enter a title for this task..." errors={fieldErrors} data-sentry-element="FormTextarea" data-sentry-source-file="task-form.tsx" />
      <div className="flex items-center gap-x-1">
        <FormSubmit data-sentry-element="FormSubmit" data-sentry-source-file="task-form.tsx">Add Task</FormSubmit>
        <Button onClick={onClose} size="sm" variant="ghost" data-sentry-element="Button" data-sentry-source-file="task-form.tsx">
          Cancel
        </Button>
      </div>
    </form>;
};