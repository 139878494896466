"use client";

import { Toaster } from "sonner";
import { ModalProvider } from "@/components/modal/provider";
import { ThemeProvider } from "@/components/theme-provider";
import { NextUIProvider } from "@nextui-org/react";
import { ConvexClientProvider } from "./convex-client-provider";
import { useTheme } from "next-themes";
import { SessionProvider } from "next-auth/react";
import { QueryProvider } from "@/providers/query-provider";
import { CustomAlertModalProvider } from "@/components/providers/custom-alert-modal-provider";
export function Providers({
  children
}: {
  children: React.ReactNode;
}) {
  return <SessionProvider data-sentry-element="SessionProvider" data-sentry-component="Providers" data-sentry-source-file="providers.tsx">
      <ThemeProvider attribute="class" defaultTheme="dark"
    // enableSystem
    disableTransitionOnChange data-sentry-element="ThemeProvider" data-sentry-source-file="providers.tsx">
        <OtherProviders data-sentry-element="OtherProviders" data-sentry-source-file="providers.tsx">{children}</OtherProviders>
      </ThemeProvider>
    </SessionProvider>;
}
function OtherProviders({
  children
}: {
  children: React.ReactNode;
}) {
  const {
    resolvedTheme
  } = useTheme();
  return <ConvexClientProvider resolvedTheme={resolvedTheme} data-sentry-element="ConvexClientProvider" data-sentry-component="OtherProviders" data-sentry-source-file="providers.tsx">
      <NextUIProvider data-sentry-element="NextUIProvider" data-sentry-source-file="providers.tsx">
        <Toaster className="dark:hidden" richColors={true} data-sentry-element="Toaster" data-sentry-source-file="providers.tsx" />
        <Toaster theme="dark" className="hidden dark:block" richColors={true} data-sentry-element="Toaster" data-sentry-source-file="providers.tsx" />
        <ModalProvider data-sentry-element="ModalProvider" data-sentry-source-file="providers.tsx">
          <QueryProvider data-sentry-element="QueryProvider" data-sentry-source-file="providers.tsx">
            <CustomAlertModalProvider data-sentry-element="CustomAlertModalProvider" data-sentry-source-file="providers.tsx" />
            {children}
          </QueryProvider>
        </ModalProvider>
      </NextUIProvider>
    </ConvexClientProvider>;
}