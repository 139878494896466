import { create } from "zustand";
import { useEffect } from "react";

interface OnboardingState {
  isOpen: boolean;
  step: number;
  totalSteps: number;
  open: () => void;
  close: () => void;
  reset: () => void;
  setStep: (step: number) => void;
  cleanup: () => void;
}

const initialState = {
  isOpen: false,
  step: 1,
  totalSteps: 4,
};

export const useOnboarding = create<OnboardingState>()((set) => ({
  ...initialState,
  open: () => set({ isOpen: true, step: 1 }),
  close: () => set({ isOpen: false }),
  reset: () => set({ step: 1 }),
  setStep: (step: number) => set({ step }),
  cleanup: () => set(initialState),
}));

// Helper hook with automatic cleanup
export const useOnboardingWithCleanup = () => {
  const store = useOnboarding();

  useEffect(() => {
    // Cleanup on component unmount
    return () => useOnboarding.getState().cleanup();
  }, []);

  return store;
};
