"use client";

import { ClerkProvider, useAuth } from "@clerk/nextjs";
import { AuthLoading, Authenticated, Unauthenticated, ConvexReactClient } from "convex/react";
import { ConvexProviderWithClerk } from "convex/react-clerk";
import { dark } from "@clerk/themes";
import { ConvexQueryCacheProvider } from "convex-helpers/react/cache/provider";
import Loading from "./app/(clerk)/loading";
interface ConvexClientProviderProps {
  children: React.ReactNode;
  resolvedTheme: string | undefined;
}
const convexUrl = process.env.NEXT_PUBLIC_CONVEX_URL!;
const convex = new ConvexReactClient(convexUrl) //   , {
//   verbose: true,
// }
;
export const ConvexClientProvider: React.FC<ConvexClientProviderProps> = ({
  children,
  resolvedTheme
}) => {
  return <ClerkProvider appearance={{
    baseTheme: resolvedTheme === "dark" ? dark : undefined
  }} publishableKey={process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY} data-sentry-element="ClerkProvider" data-sentry-component="ConvexClientProvider" data-sentry-source-file="convex-client-provider.tsx">
      <ConvexProviderWithClerk useAuth={useAuth} client={convex} data-sentry-element="ConvexProviderWithClerk" data-sentry-source-file="convex-client-provider.tsx">
        <ConvexQueryCacheProvider data-sentry-element="ConvexQueryCacheProvider" data-sentry-source-file="convex-client-provider.tsx">
          {children}
          {/* <Unauthenticated>{children}</Unauthenticated>
           <Authenticated>{children}</Authenticated>
           <AuthLoading>
            <Loading />
           </AuthLoading> */}
        </ConvexQueryCacheProvider>
      </ConvexProviderWithClerk>
    </ClerkProvider>;
};
{
  /* <ConvexProviderWithClerk client={convex} useAuth={useAuth}>
  {children}
  </ConvexProviderWithClerk> */
}