"use client";

import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useCardModal } from "@/hooks/use-card-model";
import { Actions } from "./actions";
import AddTaskDialog from "@/components/pm-ai/add-tasks/add-task-dialog";
export const CardModal = () => {
  const data = useCardModal(state => state.data);
  const isOpen = useCardModal(state => state.isOpen);
  const onClose = useCardModal(state => state.onClose);
  return <Dialog open={isOpen} onOpenChange={onClose} data-sentry-element="Dialog" data-sentry-component="CardModal" data-sentry-source-file="index.tsx">
      <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="index.tsx">
        {data && <AddTaskDialog data={data} />}
        {!data ? <Actions.Skeleton /> : <Actions data={data} />}
      </DialogContent>
    </Dialog>;
};