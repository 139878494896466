"use client";

import { useCustomModal } from "@/hooks/use-custom-modal";
import CustomModal from "@/components/automations/global/custom-modal";
import { TaskForm } from "@/components/tasks/task-form";
import { GiveawayForm } from "@/components/giveaway-components/giveaway-form";
export const CustomModalProvider = () => {
  const {
    type
  } = useCustomModal();
  if (type === "new-task") {
    return <CustomModal title="Add New Task" subheading="Create a new task for your project">
        <TaskForm />
      </CustomModal>;
  }
  if (type === "new-giveaway") {
    return <CustomModal title="Create New Giveaway" subheading="Enter a name for your giveaway to get started">
        <GiveawayForm />
      </CustomModal>;
  }
  return null;
};