"use client";

import { useEffect, useState } from "react";
import { CustomAlertModal } from "@/components/automations/global/custom-alert-modal";
export const CustomAlertModalProvider = () => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);
  if (!isMounted) {
    return null;
  }
  return <CustomAlertModal data-sentry-element="CustomAlertModal" data-sentry-component="CustomAlertModalProvider" data-sentry-source-file="custom-alert-modal-provider.tsx" />;
};