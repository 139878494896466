"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { toast } from "sonner";
import { useMutation } from "convex/react";
import { api } from "@/convex/_generated/api";
import { useAuth } from "@clerk/nextjs";
import { useRouter } from "next/navigation";
import { Input, Button } from "@nextui-org/react";
import { useCustomModal } from "@/hooks/use-custom-modal";
import { useRef, ElementRef } from "react";
const formSchema = z.object({
  title: z.string().min(2, {
    message: "Title must be at least 2 characters."
  })
});
export const GiveawayForm = () => {
  const {
    projectId,
    onClose
  } = useCustomModal();
  const {
    orgId
  } = useAuth();
  const router = useRouter();
  const formRef = useRef<ElementRef<"form">>(null);
  const createGiveaway = useMutation(api.giveaways.createGiveaway);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: ""
    }
  });
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    if (!orgId || !projectId) {
      toast.error("Missing organization or project information");
      return;
    }
    try {
      const result = await createGiveaway({
        siteId: projectId,
        orgId: orgId,
        title: values.title,
        description: "",
        startDate: new Date().toISOString(),
        endDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(),
        rewardDescription: "",
        entryActions: []
      });
      if (result?.error) {
        toast.error(result.error.message);
      } else {
        toast.success("Giveaway created successfully!");
        onClose();
        router.push(`/project/${projectId}/giveaway/${result?.data}`);
      }
    } catch (error) {
      toast.error("Failed to create giveaway");
    }
  };
  return <form ref={formRef} onSubmit={form.handleSubmit(onSubmit)} className="space-y-4" data-sentry-component="GiveawayForm" data-sentry-source-file="giveaway-form.tsx">
      <Input label="Giveaway Title" placeholder="Enter a name for your giveaway" value={form.watch("title")} onChange={e => form.setValue("title", e.target.value)} variant="flat" labelPlacement="outside" errorMessage={form.formState.errors.title?.message} description="You can customize all other details after creation." classNames={{
      label: "text-default-700",
      description: "text-default-500"
    }} data-sentry-element="Input" data-sentry-source-file="giveaway-form.tsx" />

      <div className="flex justify-end pt-4">
        <Button color="primary" type="submit" isLoading={form.formState.isSubmitting} data-sentry-element="Button" data-sentry-source-file="giveaway-form.tsx">
          Create Giveaway
        </Button>
      </div>
    </form>;
};