"use client";

import { cn } from "@/lib/utils";
import { Button } from "@nextui-org/react";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { ArrowRight } from "lucide-react";
import { useOnboardingWithCleanup } from "./use-onboarding";
function OnboardingDialog() {
  const {
    step,
    setStep,
    isOpen,
    close,
    totalSteps
  } = useOnboardingWithCleanup();
  const stepContent = [{
    title: "Welcome to Origin UI",
    description: "Discover a powerful collection of components designed to enhance your development workflow."
  }, {
    title: "Customizable Components",
    description: "Each component is fully customizable and built with modern web standards in mind."
  }, {
    title: "Ready to Start?",
    description: "Begin building amazing interfaces with our comprehensive component library."
  }, {
    title: "Get Support",
    description: "Access our extensive documentation and community resources to make the most of Origin UI."
  }];
  const handleContinue = () => {
    if (step < totalSteps) {
      setStep(step + 1);
    }
  };
  if (!isOpen) return null;
  return <Dialog open={isOpen} onOpenChange={close} data-sentry-element="Dialog" data-sentry-component="OnboardingDialog" data-sentry-source-file="onboarding.tsx">
      <DialogContent className="gap-0 p-0 [&>button:last-child]:text-white" data-sentry-element="DialogContent" data-sentry-source-file="onboarding.tsx">
        <div className="p-2">
          <img className="w-full rounded-lg" src="https://originui.com/dialog-content.png" width={382} height={216} alt="dialog" />
        </div>
        <div className="space-y-6 px-6 pb-6 pt-3">
          <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="onboarding.tsx">
            <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="onboarding.tsx">{stepContent[step - 1].title}</DialogTitle>
            <DialogDescription data-sentry-element="DialogDescription" data-sentry-source-file="onboarding.tsx">
              {stepContent[step - 1].description}
            </DialogDescription>
          </DialogHeader>
          <div className="flex flex-col justify-between gap-4 sm:flex-row sm:items-center">
            <div className="flex justify-center space-x-1.5 max-sm:order-1">
              {[...Array(totalSteps)].map((_, index) => <div key={index} className={cn("h-1.5 w-1.5 rounded-full bg-primary", index + 1 === step ? "bg-primary" : "opacity-20")} />)}
            </div>
            <DialogFooter data-sentry-element="DialogFooter" data-sentry-source-file="onboarding.tsx">
              <DialogClose asChild data-sentry-element="DialogClose" data-sentry-source-file="onboarding.tsx">
                <Button type="button" variant="ghost" onClick={close} data-sentry-element="Button" data-sentry-source-file="onboarding.tsx">
                  Skip
                </Button>
              </DialogClose>
              {step < totalSteps ? <Button className="group" type="button" color="primary" onClick={handleContinue}>
                  Next
                  <ArrowRight className="-me-1 ms-2 opacity-60 transition-transform group-hover:translate-x-0.5" size={16} strokeWidth={2} aria-hidden="true" />
                </Button> : <DialogClose asChild>
                  <Button type="button" onClick={close}>
                    Okay
                  </Button>
                </DialogClose>}
            </DialogFooter>
          </div>
        </div>
      </DialogContent>
    </Dialog>;
}
export { OnboardingDialog };