"use client";

import { useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
export const QueryProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [queryClient] = useState(() => new QueryClient());
  return <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-component="QueryProvider" data-sentry-source-file="query-provider.tsx">{children}</QueryClientProvider>;
};