"use client";

import React from "react";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useCustomModal } from "@/hooks/use-custom-modal";
type Props = {
  title: string;
  subheading: string;
  children: React.ReactNode;
};
const CustomModal = ({
  title,
  subheading,
  children
}: Props) => {
  const {
    isOpen,
    onClose
  } = useCustomModal();
  return <Dialog open={isOpen} onOpenChange={onClose} data-sentry-element="Dialog" data-sentry-component="CustomModal" data-sentry-source-file="custom-modal.tsx">
      <DialogContent className="h-screen overflow-scroll !rounded-2xl bg-card md:h-fit md:max-h-[700px]" data-sentry-element="DialogContent" data-sentry-source-file="custom-modal.tsx">
        <DialogHeader className="text-left" data-sentry-element="DialogHeader" data-sentry-source-file="custom-modal.tsx">
          <DialogTitle className="text-2xl font-bold" data-sentry-element="DialogTitle" data-sentry-source-file="custom-modal.tsx">{title}</DialogTitle>
          <DialogDescription data-sentry-element="DialogDescription" data-sentry-source-file="custom-modal.tsx">{subheading}</DialogDescription>
          {children}
        </DialogHeader>
      </DialogContent>
    </Dialog>;
};
export default CustomModal;