import { create } from "zustand";

interface CustomModalStore {
  isOpen: boolean;
  type?: "new-task" | "new-giveaway" | "other-type"; // Added new-giveaway type
  projectId?: string;
  onOpen: (
    type: "new-task" | "new-giveaway" | "other-type",
    projectId?: string,
  ) => void;
  onClose: () => void;
}

export const useCustomModal = create<CustomModalStore>((set) => ({
  isOpen: false,
  type: undefined,
  projectId: undefined,
  onOpen: (type, projectId) => set({ isOpen: true, type, projectId }),
  onClose: () => set({ isOpen: false, type: undefined, projectId: undefined }),
}));
