import { useAlertModal } from "@/hooks/use-alert-modal";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from "@nextui-org/react";
export const CustomAlertModal = () => {
  const {
    isOpen,
    isLoading,
    item,
    onClose,
    onDelete,
    setIsLoading
  } = useAlertModal();
  const handleDelete = async () => {
    if (!onDelete) return;
    try {
      setIsLoading(true);
      await onDelete();
      onClose();
    } catch (error) {
      console.error("[CustomAlertModal] - Error during deletion:", error);
    } finally {
      setIsLoading(false);
    }
  };
  return <Modal isOpen={isOpen} onOpenChange={onClose} isDismissable={!isLoading} hideCloseButton={isLoading} data-sentry-element="Modal" data-sentry-component="CustomAlertModal" data-sentry-source-file="custom-alert-modal.tsx">
      <ModalContent data-sentry-element="ModalContent" data-sentry-source-file="custom-alert-modal.tsx">
        {() => <>
            <ModalHeader className="flex flex-col gap-1">
              Are you absolutely sure?
            </ModalHeader>
            <ModalBody>
              This action cannot be undone. This will permanently delete the{" "}
              {item} and remove it from our servers.
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose} isDisabled={isLoading}>
                Cancel
              </Button>
              <Button color="primary" isLoading={isLoading} onPress={handleDelete}>
                Delete
              </Button>
            </ModalFooter>
          </>}
      </ModalContent>
    </Modal>;
};